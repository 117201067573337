import React from 'react';
import { Box, Typography } from '@mui/material';

interface Props {
    colors: { color: string; label: string }[];
}

function GraphKey(props: Props) {
    return (
        <Box
            sx={{
                position: 'absolute',
                bottom: { xs: 2, sm: 20 },
                left: {xs: 0, sm: 20},
                width: { xs: '150px', sm: 150 },
                bgcolor: 'white',
                boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                p: 2,
                borderRadius: 2,
                zIndex: 10,
                border: '1px solid #e0e0e0',
                margin: { xs: '10px', sm: 0 },
            }}
        >
            <Typography variant="h6" sx={{ color: '#333', fontSize: { xs: '0.8rem', sm: '1rem' } }}>
                Key
            </Typography>
            {props.colors.map(({ color, label }, index) => (
                <Box
                    key={index}
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-start',
                        mt: 1,
                    }}
                >
                    <Box
                        sx={{
                            width: 20,
                            height: 20,
                            borderRadius: '50%',
                            backgroundColor: color,
                            marginRight: 2,
                        }}
                    ></Box>
                    <Typography sx={{ color: '#333', fontSize: '0.8rem' }}>{label}</Typography>
                </Box>
            ))}
        </Box>
    );
}

export default GraphKey;
