import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import Chip from '@mui/material/Chip';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import DevicesRoundedIcon from '@mui/icons-material/DevicesRounded';
import EdgesensorHighRoundedIcon from '@mui/icons-material/EdgesensorHighRounded';
import ViewQuiltRoundedIcon from '@mui/icons-material/ViewQuiltRounded';
import Graph from "./Graph";

const items = [
    {
        icon: <ViewQuiltRoundedIcon/>,
        title: 'Employee Empowerment',
        description:
            'Break away from silos and promote collaboration by connecting people within your organisation. Find with the person with the skills or knowledge you need. See cross collaboration in action.',
        imageLight: 'url("/static/images/templates/templates-images/dash-light.png")',
        imageDark: 'url("/static/images/templates/templates-images/dash-dark.png")',
    },
    {
        icon: <EdgesensorHighRoundedIcon/>,
        title: 'Risk Mitigation',
        description:
            'Identify risks in your workforce by identifying the key players, ensure projects have the resources they need and call out key points of failure.',
        imageLight: 'url("/static/images/templates/templates-images/mobile-light.png")',
        imageDark: 'url("/static/images/templates/templates-images/mobile-dark.png")',
    },
    {
        icon: <DevicesRoundedIcon/>,
        title: 'Capability Forecasting',
        description:
            'Equip yourself with the knowledge to drive recruiting, organisational restructuring and training decisions to best service your clients.',
        imageLight: 'url("/static/images/templates/templates-images/devices-light.png")',
        imageDark: 'url("/static/images/templates/templates-images/devices-dark.png")',
    },
];

export default function Features() {
    const [selectedItemIndex, setSelectedItemIndex] = React.useState(0);

    const handleItemClick = (index: number) => {
        setSelectedItemIndex(index);
    };

    const selectedFeature = items[selectedItemIndex];

    return (
        <Container id="features" sx={{py: {xs: 2, sm: 4}}}>
            <Grid container spacing={6}>
                <Grid item xs={12} md={6}>
                    <div>
                        <Typography component="h2" variant="h4" color="text.primary">
                            Features
                        </Typography>
                        <Typography
                            variant="body1"
                            color="text.secondary"
                            sx={{mb: {xs: 2, sm: 4}}}
                        >
                            An atlas for your organisation.
                            Locate the people with required skills and knowledge on demand.
                            Identify and mitigate risks. Drive recruitment.
                            Improvement managerial decision making.
                            Bridge geographical gaps.
                            Remove silos.
                            Empower employees.
                            Promote collaboration.
                        </Typography>
                    </div>
                    <Grid container item gap={1} sx={{display: {xs: 'auto', sm: 'none'}}}>
                        {items.map(({title}, index) => (
                            <Chip
                                key={index}
                                label={title}
                                onClick={() => handleItemClick(index)}
                                sx={{
                                    borderColor: (theme) => {
                                        if (theme.palette.mode === 'light') {
                                            return selectedItemIndex === index ? 'primary.light' : '';
                                        }
                                        return selectedItemIndex === index ? 'primary.light' : '';
                                    },
                                    background: (theme) => {
                                        if (theme.palette.mode === 'light') {
                                            return selectedItemIndex === index ? 'none' : '';
                                        }
                                        return selectedItemIndex === index ? 'none' : '';
                                    },
                                    backgroundColor: selectedItemIndex === index ? 'primary.main' : '',
                                    '& .MuiChip-label': {
                                        color: selectedItemIndex === index ? '#fff' : '',
                                    },
                                }}
                            />
                        ))}
                    </Grid>
                    <Box
                        component={Card}
                        variant="outlined"
                        sx={{
                            display: {xs: 'block', sm: 'none'},
                            minHeight: 280,
                            mt:5,
                            width: '100%'
                        }}
                    >
                        <Container
                            sx={{
                                height: 450,
                                display: 'block',
                                width: '100%'
                            }}
                        >
                            {selectedItemIndex === 0 && (
                                <Graph data={'graph/feature_employee_empowerment.json'}/>
                            )}
                            {selectedItemIndex === 1 && (
                                <Graph data={'graph/feature_risk_mitigation.json'}/>
                            )}
                            {selectedItemIndex === 2 && (
                                <Graph data={'graph/feature_capability_forecasting.json'}/>
                            )}
                        </Container>
                        <Box sx={{pb: 2, px:2}}>
                            <Typography color="text.primary" variant="body2" fontWeight="bold">
                                {selectedFeature.title}
                            </Typography>
                            <Typography color="text.secondary" variant="body2" sx={{my: 0.5}}>
                                {selectedFeature.description}
                            </Typography>
                        </Box>
                    </Box>
                    <Stack
                        direction="column"
                        justifyContent="center"
                        alignItems="flex-start"
                        spacing={2}
                        useFlexGap
                        sx={{width: '100%', display: {xs: 'none', sm: 'flex'}}}
                    >
                        {items.map(({icon, title, description}, index) => (
                            <Card
                                key={index}
                                variant="outlined"
                                component={Button}
                                onClick={() => handleItemClick(index)}
                                sx={{
                                    p: 3,
                                    height: 'fit-content',
                                    width: '100%',
                                    background: 'none',
                                    backgroundColor:
                                        selectedItemIndex === index ? 'action.selected' : undefined,
                                    borderColor: (theme) => {
                                        if (theme.palette.mode === 'light') {
                                            return selectedItemIndex === index
                                                ? 'primary.light'
                                                : 'grey.200';
                                        }
                                        return selectedItemIndex === index ? 'primary.dark' : 'grey.800';
                                    },
                                }}
                            >
                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        textAlign: 'left',
                                        flexDirection: {xs: 'column', md: 'row'},
                                        alignItems: {md: 'center'},
                                        gap: 2.5,
                                    }}
                                >
                                    <Box
                                        sx={{
                                            color: (theme) => {
                                                if (theme.palette.mode === 'light') {
                                                    return selectedItemIndex === index
                                                        ? 'primary.main'
                                                        : 'grey.300';
                                                }
                                                return selectedItemIndex === index
                                                    ? 'primary.main'
                                                    : 'grey.700';
                                            },
                                        }}
                                    >
                                        {icon}
                                    </Box>
                                    <Box sx={{textTransform: 'none'}}>
                                        <Typography
                                            color="text.primary"
                                            variant="body2"
                                            fontWeight="bold"
                                        >
                                            {title}
                                        </Typography>
                                        <Typography
                                            color="text.secondary"
                                            variant="body2"
                                            sx={{my: 0.5}}
                                        >
                                            {description}
                                        </Typography>
                                    </Box>
                                </Box>
                            </Card>
                        ))}
                    </Stack>
                </Grid>
                <Grid
                    item
                    xs={12}
                    md={6}
                    sx={{display: {xs: 'flex', sm: 'flex'}, width: '100%'}}
                >
                    <Card
                        variant="outlined"
                        sx={{
                            height: '100%',
                            width: '100%',
                            display: {xs: 'none', sm: 'flex'},
                        }}
                    >

                        {selectedItemIndex === 0 && (
                            <Graph data={'graph/feature_employee_empowerment.json'}/>
                        )}
                        {selectedItemIndex === 1 && (
                            <Graph data={'graph/feature_risk_mitigation.json'}/>
                        )}
                        {selectedItemIndex === 2 && (
                            <Graph data={'graph/feature_capability_forecasting.json'}/>
                        )}
                    </Card>
                </Grid>
            </Grid>
        </Container>
    );
}
