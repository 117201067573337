import * as React from 'react';
import {alpha, Icon} from '@mui/material';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TypingAnimation from "./TypingAnimation";
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';

const scrollToSection = (sectionId: string) => {
    const sectionElement = document.getElementById(sectionId);
    const offset = 128;
    if (sectionElement) {
        const targetScroll = sectionElement.offsetTop - offset;
        sectionElement.scrollIntoView({behavior: 'smooth'});
        window.scrollTo({
            top: targetScroll,
            behavior: 'smooth',
        });
    }
};

export default function Hero() {
    let windowHeight = window.innerHeight
    return (
        <Box
            id="hero"
            sx={(theme) => ({
                width: '100%',
                position: 'relative',
                height: windowHeight,
                backgroundImage:
                    theme.palette.mode === 'light'
                        ? 'linear-gradient(180deg, #CEE5FD, #FFF)'
                        : `linear-gradient(#02294F, ${alpha('#090E10', 0.0)})`,
                backgroundSize: '100% 20%',
                backgroundRepeat: 'no-repeat',
            })}
        >
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    pt: {xs: 14, sm: 20},
                    pb: {xs: 8, sm: 12},
                }}
            >
                <Stack spacing={2} useFlexGap sx={{width: {xs: '100%', sm: '70%'}}}>
                    <Typography
                        variant="h1"
                        sx={{
                            display: 'flex',
                            flexDirection: {xs: 'column', md: 'row'},
                            alignSelf: 'center',
                            textAlign: 'center',
                            fontSize: 'clamp(3.5rem, 10vw, 4rem)',
                        }}
                    >
                        Org
                        <Typography
                            component="span"
                            variant="h1"
                            sx={{
                                fontSize: 'clamp(3rem, 10vw, 4rem)',
                                color: (theme) =>
                                    theme.palette.mode === 'light' ? 'primary.light' : 'primary.main',
                            }}
                        >
                            Atlas
                        </Typography>
                    </Typography>
                    <Typography
                        textAlign="center"
                        color="text.secondary"
                        sx={{alignSelf: 'center', width: {sm: '100%', md: '80%'}}}
                    >
                        Synchronise knowledge across your organisation like never before.
                    </Typography>
                    <Stack
                        direction={{xs: 'column', sm: 'row'}}
                        alignSelf="center"
                        spacing={1}
                        useFlexGap
                        sx={{pt: 2, width: {xs: '100%', sm: 'auto'}}}
                    >
                        {/*<TextField*/}
                        {/*    id="outlined-basic"*/}
                        {/*    hiddenLabel*/}
                        {/*    size="small"*/}
                        {/*    variant="outlined"*/}
                        {/*    aria-label="Enter your email address"*/}
                        {/*    placeholder="Your email address"*/}
                        {/*    inputProps={{*/}
                        {/*        autoComplete: 'off',*/}
                        {/*        'aria-label': 'Enter your email address',*/}
                        {/*    }}*/}
                        {/*/>*/}
                        {/*<Button variant="contained" color="primary">*/}
                        {/*    Register Interest*/}
                        {/*</Button>*/}
                    </Stack>
                </Stack>
                <Box
                    sx={(theme) => ({
                        mt: {xs: 2, sm: 4},
                        p: {xs: 2, sm: 4},
                        alignSelf: 'center',
                        // height: {xs: 300, sm: 300},
                        width: '100%',
                        backgroundImage:
                            theme.palette.mode === 'light'
                                ? 'url("/static/images/templates/templates-images/hero-light.png")'
                                : 'url("/static/images/templates/templates-images/hero-dark.png")',
                        backgroundSize: 'cover',
                        borderRadius: '10px',
                        outline: '1px solid',
                        outlineColor:
                            theme.palette.mode === 'light'
                                ? alpha('#BFCCD9', 0.5)
                                : alpha('#9CCCFC', 0.1),
                        boxShadow:
                            theme.palette.mode === 'light'
                                ? `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`
                                : `0 0 24px 12px ${alpha('#033363', 0.2)}`,
                        overflow: 'hidden' // Ensure the content does not overflow
                    })}
                >
                    <TypingAnimation/>
                </Box>
            </Container>
            <Container
                sx={{
                    position: 'absolute',
                    left: '50%',
                    bottom: '50px',
                    transform: 'translateX(-50%)',
                    textAlign: 'center',
                }}
            >
                <KeyboardDoubleArrowDownIcon fontSize='large' onClick={() => scrollToSection('#graph_demo')}/>
            </Container>
        </Box>
    );
}
