import React from 'react';
import { Box, Typography, IconButton } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';

interface PopupProps {
    title: string;
    content: string;
    visible: boolean;
    setVisibility: any;
}


class GraphPopup extends React.Component<PopupProps> {
    constructor(props: PopupProps) {
        super(props);
    }

    handleClose = () => {
        this.props.setVisibility(false);
    };

    render() {
        if (!this.props.visible) return null;

        return (
            <Box
                sx={{
                    position: 'absolute',
                    top: { xs: 10, sm: 20 },
                    left: { xs: 0, sm: 20 },
                    width: { xs: '90%', sm: 300 },
                    maxWidth: '100%',
                    bgcolor: 'white',
                    boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.1)',
                    p: 2,
                    borderRadius: 2,
                    zIndex: 10,
                    border: '1px solid #e0e0e0',
                    margin: { xs: '10px', sm: 0 },
                }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        mb: 2,
                    }}
                >
                    <Typography variant="h6" sx={{ color: '#333', fontSize: { xs: '1.2rem', sm: '1.5rem' } }}>
                        {this.props.title}
                    </Typography>
                    <IconButton onClick={this.handleClose}>
                        <CloseIcon sx={{ color: '#666' }} />
                    </IconButton>
                </Box>
                <Typography sx={{ color: '#555', fontSize: { xs: '0.875rem', sm: '1rem' } }}>
                    {this.props.content}
                </Typography>
            </Box>
        );
    }
}

export default GraphPopup;
