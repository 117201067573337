import React, {Component} from 'react';
import {alpha} from "@mui/material";
import TypingAnimation from "./TypingAnimation";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Button from "@mui/material/Button";

// Define the state type
interface AboutUsState {
    isExpanded: boolean;
}

class AboutUs extends Component<{}, AboutUsState> {
    constructor(props: {}) {
        super(props);
        this.state = {
            isExpanded: false,
        };
    }

    handleToggle = () => {
        this.setState((prevState) => ({
            isExpanded: !prevState.isExpanded,
        }));
    };

    render() {
        const {isExpanded} = this.state;

        return (
            <Container
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    pt: {xs: 8, sm: 12},
                    pb: {xs: 8, sm: 12},
                }}
            >
                <Box
                    sx={(theme) => ({
                        mt: {xs: 2, sm: 4},
                        p: {xs: 2, sm: 4},
                        alignSelf: 'center',
                        width: '100%',
                        backgroundImage:
                            theme.palette.mode === 'light'
                                ? 'url("/static/images/templates/templates-images/hero-light.png")'
                                : 'url("/static/images/templates/templates-images/hero-dark.png")',
                        backgroundSize: 'cover',
                        borderRadius: '10px',
                        outline: '1px solid',
                        outlineColor:
                            theme.palette.mode === 'light'
                                ? alpha('#BFCCD9', 0.5)
                                : alpha('#9CCCFC', 0.1),
                        boxShadow:
                            theme.palette.mode === 'light'
                                ? `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`
                                : `0 0 24px 12px ${alpha('#033363', 0.2)}`,
                        overflow: 'hidden' // Ensure the content does not overflow
                    })}
                >
                    <div className="about-us-container">
                        <h1>Welcome to OrgAtlas</h1>
                        <p>
                            Founded in 2024, OrgAtlas was born from the vision of Daniel Lambert, a seasoned management
                            consultant and systems analyst. Daniel has a rich history of working with large
                            organizations
                            across both government and private sectors, bringing technical expertise to software
                            startups
                            and global resource companies with extensive supply chains. Through his experiences, he
                            identified significant inefficiencies in organizational overheads and saw an opportunity to
                            empower employees, enhance managerial insights, reduce operational overheads, mitigate
                            risks,
                            and support decision-makers.
                        </p>
                        {isExpanded && (
                            <>
                                <h2>Our Mission</h2>
                                <p>
                                    At OrgAtlas, our mission is simple: to increase visibility within organizations. We
                                    believe that transparency and trust are the cornerstones of a successful workplace,
                                    and
                                    our solutions are designed to foster these values.
                                </p>
                                <h2>Our Unique Approach</h2>
                                <p>
                                    What sets OrgAtlas apart is our innovative transactive memory system software.
                                    Unlike
                                    traditional knowledge management systems that quickly become outdated and require
                                    constant maintenance, our system promotes communication through a dynamically
                                    adjusting
                                    and growing knowledge network focused on people. Our software is not just a
                                    repository
                                    of information; it is an atlas that helps locate the people with the specialist
                                    skills,
                                    knowledge, or capabilities you need, answering the critical questions of "who knows
                                    about this" or "who is responsible for this."
                                </p>
                                <h2>Our Products and Services</h2>
                                <p>
                                    OrgAtlas Transactive Memory System Software: A cutting-edge tool designed to enhance
                                    communication and collaboration within your organization by connecting employees
                                    with
                                    the right knowledge and expertise.
                                </p>
                                <p>
                                    Consulting Services: Tailored consulting to help you implement and maximize the
                                    benefits
                                    of our transactive memory system.
                                </p>
                                <h2>Who We Serve</h2>
                                <p>
                                    Our solutions are designed for specialist organizations, those with geographically
                                    distributed workforces (including remote and hybrid work environments), and large
                                    enterprises seeking to improve internal communication and knowledge management.
                                </p>
                                <h2>Our Vision</h2>
                                <p>
                                    Looking to the future, OrgAtlas is committed to working closely with our clients to
                                    tailor our solutions to their specific needs, providing a professional and personal
                                    touch that ensures success. We aim to continue making a significant impact on the
                                    daily
                                    lives of employees and managers by putting people back at the center of the
                                    workplace.
                                </p>
                                <h2>Our Impact</h2>
                                <p>
                                    We believe that by promoting connections between people and breaking down
                                    organizational
                                    silos, OrgAtlas can make a huge difference in the community and industry. Our goal
                                    is to
                                    highlight the importance of people in the workplace, ensuring that everyone can
                                    easily
                                    access the knowledge and expertise they need to succeed.
                                </p>
                                <h2>Join Us</h2>
                                <p>
                                    Join us on our journey to create more transparent, trust-filled workplaces where
                                    communication flows freely, and everyone can contribute to their fullest potential.
                                    At
                                    OrgAtlas, we are dedicated to making your organization a better place to work.
                                </p>
                            </>
                        )}
                        <Button onClick={this.handleToggle} variant="outlined" color="primary">
                            {isExpanded ? 'Show Less' : 'Continue Reading'}
                        </Button>
                    </div>
                </Box>
            </Container>
        );
    }
}

export default AboutUs;
