import * as React from 'react';
import * as d3 from 'd3';
import {Box, Container} from "@mui/material";
import {alpha} from "@mui/material";
import Graph from "./Graph";

export default function GraphDemo() {
    const boxRef = React.useRef<HTMLDivElement>(null);
    const [boxWidth, setBoxWidth] = React.useState<number>(0);
    const [boxHeight, setBoxHeight] = React.useState<number>(0);

    React.useEffect(() => {
        const handleResize = () => {
            if (boxRef.current) {
                setBoxWidth(boxRef.current.offsetWidth);
                setBoxHeight(boxRef.current.offsetHeight);
            }
        };

        const resizeObserver = new ResizeObserver(() => handleResize());
        if (boxRef.current) {
            resizeObserver.observe(boxRef.current);
        }

        handleResize();

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    return (
        <Container
            id="#graph_demo"
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Box
                ref={boxRef}
                sx={(theme) => ({
                    alignSelf: 'center',
                    height: {xs: window.innerHeight * 0.8, sm: window.innerHeight * 0.8},
                    width: '100%',
                    backgroundImage:
                        theme.palette.mode === 'light'
                            ? 'url("/static/images/templates/templates-images/hero-light.png")'
                            : 'url("/static/images/templates/templates-images/hero-dark.png")',
                    backgroundSize: 'cover',
                    borderRadius: '10px',
                    outline: '1px solid',
                    outlineColor:
                        theme.palette.mode === 'light'
                            ? alpha('#BFCCD9', 0.5)
                            : alpha('#9CCCFC', 0.1),
                    boxShadow:
                        theme.palette.mode === 'light'
                            ? `0 0 12px 8px ${alpha('#9CCCFC', 0.2)}`
                            : `0 0 24px 12px ${alpha('#033363', 0.2)}`,
                    position: 'relative',
                })}
            >
                <Graph data={'graph/main.json'}/>
            </Box>
        </Container>
    );
}
