import * as React from 'react';
import {TypeAnimation} from 'react-type-animation';

const story = [
    'Let\'s set the scene...',
    1000,
    'BUSINESS CORP HQ: The camera pans across a bustling open-plan office filled with employees at their desks, engaged in various tasks. The hum of activity creates a lively atmosphere. We zoom in on a glass-walled conference room where a meeting is about to begin.',
    1000,
    'The room is equipped with a large table, several chairs, and a screen displaying a presentation titled "AI-Powered Data Analytics Platform." Five team members from different departments take their seats.\n',
    1000,
    'JESSICA (30s, Head of R&D, sharp and focused) stands at the head of the table.\n',
    1000,
    'JESSICA: Good morning, everyone. Let\'s dive right into our project updates. Daniel, how\'s the AI platform development progressing?\n',
    1000,
    'DANIEL (30s, Software Engineer, tech-savvy, casual attire) looks up from his laptop.\n',
    1000,
    'DANIEL: We’ve just completed the final testing phase. The predictive analytics feature is performing even better than we anticipated.\n',
    1000,
    'JESSICA: That\'s fantastic news. Emma, what’s the marketing strategy looking like?\n',
    1000,
    'EMMA (20s, Marketing Specialist, creative and energetic) adjusts her glasses and smiles.\n',
    1000,
    'EMMA: We’ve got a multi-channel campaign ready to roll out. Social media teasers, blog posts, and a webinar series to generate buzz and educate potential customers.\n',
    1000,
    'JESSICA: Perfect. Raj, how are we doing on the sales front?\n',
    1000,
    'RAJ (40s, Sales Manager, charismatic and confident) leans forward.\n',
    1000,
    'RAJ: We’ve identified several key accounts that are very interested. I’ve scheduled demos for next week. The client feedback so far has been very positive.\n',
    1000,
    'JESSICA: Great to hear. Sarah, what’s the plan for customer support once we launch?\n',
    1000,
    'SARAH (late 20s, Customer Support Lead, empathetic and organized) nods.\n',
    1000,
    'SARAH: We’re setting up a dedicated support team specifically for this platform. We’ll have comprehensive training sessions and detailed documentation ready for our users.\n',
    1000,
    'JESSICA: Excellent. And finally, Olivia, how\'s the employee wellness program going?\n',
    1000,
    'OLIVIA (30s, HR Manager, warm and approachable) smiles.\n',
    1000,
    'OLIVIA: We’ve rolled out the first phase. Employees are already engaging with the new wellness resources, and feedback has been overwhelmingly positive.\n',
    1000,
    'JESSICA: That’s wonderful. It’s crucial for us to support our team, especially with such an ambitious project on our hands.\n',
    1000,
    'The team members exchange satisfied glances, feeling the collective energy and commitment in the room.\n',
    1000,
    'JESSICA: Alright, let’s keep up the great work, everyone. We’re on track to make a significant impact with this launch.\n',
    1000,
    'The camera pans out as the team continues to discuss finer details, capturing the essence of a cohesive and dynamic organization at work.\n',
    1000,
    'FADE OUT.\n'
    ];

export default function TypingAnimation() {
    return (
        <TypeAnimation
            sequence={story}
            speed={60}
            wrapper="span"
            cursor={true}
            repeat={Infinity}
            style={{fontSize: '1.5em', display: 'inline-block'}}
            deletionSpeed={95}
        />
    );
}
